import logo from "./logo.png";
import "./App.css";
import { CountdownMonths } from "./CountdownMonths";
import linkTree from "./linktree.png";
import twitterIcon from "./twitter.png";
import telegramIcon from "./telegram.png";
import youtubeIcon from "./youtube.png";
import mediumIcon from "./medium.png";

function App() {
  return (
    <div>
      <div className="header-section">
        <div className="menu-flex menu-gap"><a href="https://retik.com/" target="_blank"><img src={logo} className="logo-css" /></a>
        <div className="menu-flex">
          <a href="https://retik.com/" target="_blank" className="menu-animation">Buy</a>
          <a href="https://swap.retik.com/" target="_blank" className="menu-animation">Swap</a>
          <a href="https://retikcards.com/" target="_blank" className="menu-animation">Card</a>
        </div>
        </div>
        <button className="header__cta">Connect Wallet</button>
      </div>
      <div className="section-bg">
        <div className="section-panel">
          <h1 className="staking__info">Retik Staking</h1>
          <div>
            <CountdownMonths />
          </div>
        </div>
        <footer className="container text-center">
          <div className="footer">
            <div>
              <div className="text-center footer-p">
                <p><b>RETIK FINANCE</b> 2024© Retik LLC | All Rights Reserved.</p>
              </div>
            </div>
            <div>
              <div className="disclamier">
                <b>Disclaimer:</b> Cryptocurrency may be unregulated in your
                jurisdiction. The value of cryptocurrencies may fluctuate.
                Profits may be subject to capital gains or other taxes
                applicable in your jurisdiction
              </div>
              <div className="social-flex">
                <a href="https://linktr.ee/retikfinance" target="_blank">
                  <img src={linkTree} />
                </a>
                <a href="https://twitter.com/retikfinance" target="_blank">
                  <img src={twitterIcon} />
                </a>
                <a href="https://twitter.com/retikfinance" target="_blank">
                  <img src={telegramIcon} />
                </a>
                <a href="https://www.youtube.com/@retikfinance" target="_blank">
                  <img src={youtubeIcon} />
                </a>
                <a href="https://medium.com/@retikfinance" target="_blank">
                  <img src={mediumIcon} />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
